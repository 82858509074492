import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from "../model/user";
import {HttpClient} from "@angular/common/http";
import {Login} from "../architecture/model/login";
import {AuthToken} from "../architecture/model/auth-token";
import {AuthTokenService} from "./auth-token.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient,
              private authTokenService: AuthTokenService) {
  }

  signup(user: User): Observable<any> {
    const url = `${this.apiUrl}/auth/signup`;
    const body = JSON.stringify(user);

    return this.http.post(url, body);
  }

  signin(login: Login): Observable<AuthToken> {
    const url = `${this.apiUrl}/auth/signin`;
    const body = JSON.stringify(login);

    return this.http.post<AuthToken>(url, body);
  }

  signout(): void {
    this.authTokenService.clean();
  }
}
