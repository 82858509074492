import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  MessageNotificationService
} from "../../architecture/services/message-notification/message-notification.service";
import {AuthService} from "../../services/auth.service";
import {User} from "../../model/user";
import {Role} from "../../model/role";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.sass']
})
export class NewUserComponent {

  registerForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private messageNotificationService: MessageNotificationService,
              private authService: AuthService,
              private router: Router) {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      retypePassword: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.registerForm.valid) {
      const user: User = new User(
        this.registerForm.value.username,
        this.registerForm.value.email,
        this.registerForm.value.password,
        [Role.USER]
      );

      this.authService.signup(user).subscribe(
        response => {
          this.messageNotificationService.notifySuccess('Register successful!', 'Welcome to ChatHelpy');
          this.router.navigate(['/login']);
        },
        error => {
          this.messageNotificationService.notifyException(error);
        }
      );
    }
  }
}
