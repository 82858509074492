<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <label for="username" class="form-label">Username:</label>
    <input type="email" class="form-control" id="username" placeholder="name@example.com" formControlName="username"
           required>
  </div>
  <div class="mb-3">
    <label for="password" class="form-label">Password:</label>
    <input type="password" class="form-control" id="password" formControlName="password"/>
  </div>
  <div class="col-auto">
    <button type="submit" class="btn btn-primary mb-3">Login</button>
  </div>
</form>
