import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './home/home.component';
import {MessageComponent} from "./architecture/component/message/message.component";
import {MessageNotificationService} from "./architecture/services/message-notification/message-notification.service";
import {MenuComponent} from './menu/menu.component';
import {NewUserComponent} from './components/new-user/new-user.component';
import {BottonComponent} from './botton/botton.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthService} from "./services/auth.service";
import {HeaderInterceptor} from "./architecture/interceptor/header-interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MessageComponent,
    MenuComponent,
    NewUserComponent,
    BottonComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    MessageNotificationService,
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
