import { Injectable } from '@angular/core';
import {AuthToken} from "../architecture/model/auth-token";

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  
  private readonly TOKEN_KEY = 'auth_token';

  constructor() { }
  
  getToken(): AuthToken | null {
    const tokenString = localStorage.getItem(this.TOKEN_KEY);
    if (tokenString) return JSON.parse(tokenString);
    return null;
  }
  
  clean(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  setToken(authToken: AuthToken) {
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(authToken));
  }
  
  isTokenValid(): boolean {
    // TODO: check expiration date?
    return this.getToken() != null;
  }
}
