import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Observer} from "rxjs";
import {AuthTokenService} from "./auth-token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthMonitorService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(private authTokenService: AuthTokenService) {
  }
  
  notify() {
    this.isAuthenticatedSubject.next(this.authTokenService.isTokenValid());
  }
  
  register(observer: Observer<boolean>): void {
    this.isAuthenticatedSubject.subscribe(observer);
  }
}
