import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {AuthMonitorService} from "../services/auth-monitor.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {

  userAuthenticated: boolean = false;

  constructor(private authService: AuthService,
              private authMonitorService: AuthMonitorService) {
  }

  ngOnInit() {
    this.authMonitorService.register({
      next: value => this.userAuthenticated = value,
      error: err => {
        console.log(err);
        this.userAuthenticated = false;
      },
      complete: () => {
      },
    });
  }

  handleLogout(event: Event) {
    event.preventDefault();
    this.authService.signout();
    this.authMonitorService.notify();
  }
}
