import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  MessageNotificationService
} from "../../architecture/services/message-notification/message-notification.service";
import {Login} from "../../architecture/model/login";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {AuthMonitorService} from "../../services/auth-monitor.service";
import {AuthTokenService} from "../../services/auth-token.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {

  loginForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private messageNotificationService: MessageNotificationService,
              private authService: AuthService,
              private authMonitorService: AuthMonitorService,
              private authTokenService: AuthTokenService,
              private router: Router) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const login: Login = new Login(
        this.loginForm.value.username,
        this.loginForm.value.password
      );

      this.authService.signin(login).subscribe(
        response => {
          this.authTokenService.setToken(response);
          this.messageNotificationService.notifySuccess(`Welcome ${response.username}`, 'Welcome to ChatHelpy');
          this.authMonitorService.notify();
          this.router.navigate(['/home']);
        },
        error => {
          this.authMonitorService.notify();
          this.messageNotificationService.notifyException(error);
        });
    }
  }
}
