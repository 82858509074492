import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthTokenService} from "../services/auth-token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private autuTokenService: AuthTokenService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ): Observable<boolean> {
    const someCondition = this.autuTokenService.isTokenValid();

    return of(someCondition).pipe(
      map((conditionResult: boolean) => {
        if (conditionResult) {
          // Allow access to the route
          return true;
        } else {
          // Deny access and navigate to a different route
          this.router.navigate(['/login']);
          return false;
        }
      }),
      );
  }
}

