import {Severity} from './severity.enum';

export class Message {

  constructor(
    public severity: Severity = Severity.INFO,
    public summary: string,
    public detail: string|null
  ) {}

  get severityClass() {
    if (this.severity === Severity.INFO) return 'primary';
    return this.severity.toString();
  }

  get info(): boolean {
    return this.severity === Severity.INFO;
  }

  get success(): boolean {
    return this.severity === Severity.SUCCESS;
  }

  get warning(): boolean {
    return this.severity === Severity.WARN;
  }

  get error(): boolean {
    return this.severity === Severity.ERROR;
  }
}
