import {Role} from "./role";

export class User {
  username: string;
  email: string;
  password: string;
  roles: Role[];

  constructor(
    username: string,
    email: string,
    password: string,
    roles: Role[]
  ) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.roles = roles;
  }
}