import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Message} from './message';
import {Severity} from './severity.enum';

@Injectable({
  providedIn: 'root'
})
export class MessageNotificationService {

  notificationChange: Subject<Message> = new Subject<Message>();

  constructor() {
  }

  notify(message: Message) {
    this.notificationChange.next(message);
  }

  notifySuccess(summary: string, detail: string|null) {
    this.notify(new Message(Severity.SUCCESS, summary, detail));
  }

  notifyInfo(summary: string, detail: string|null) {
    this.notify(new Message(Severity.INFO, summary, detail));
  }

  notifyError(summary: string, detail: string|null) {
    this.notify(new Message(Severity.ERROR, summary, detail));
  }

  notifyException(error: any): void {
    const errorMessage: string = error.error ? error.error.message : error.message ? error.message : 'Unknown error';
    this.notifyError(errorMessage, null);
  }

  notifyWarn(summary: string, detail: string|null) {
    this.notify(new Message(Severity.WARN, summary, detail));
  }
}
