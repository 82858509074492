import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageNotificationService} from '../../services/message-notification/message-notification.service';
import {Subscription} from 'rxjs';
import {Message} from '../../services/message-notification/message';
import {Severity} from '../../services/message-notification/severity.enum';

// @ts-ignore
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  messages: Message[] = [];
  private subscription!: Subscription;

  constructor(private messageNotificationService: MessageNotificationService) { }

  ngOnInit() {
    this.subscribeToNotifications();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private subscribeToNotifications(): any {
    this.subscription = this.messageNotificationService.notificationChange.subscribe(
      notification => {
        // this.messages.length = 0;
        this.messages.push(notification);

        setTimeout(() => {
          this.messages.length = 0;
        }, this.timeoutByMessageType(notification));
      }
    );
  }

  private timeoutByMessageType(message: Message) {
    if (message.severity === Severity.ERROR) {
      return 10 * 1000 * 10;
    } else if (message.severity === Severity.WARN) {
      return 10 * 1000 * 8;
    } else {
      return 10 * 1000 * 5;
    }
  }
}
